import React, { useCallback, useEffect, useState } from 'react';
import { Grid, GridCol, GridRow } from '@naf/grid';
import { breakpoints, spacing, themeLight, radius } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { nafColor } from '@nafcore/theme';
import styled from 'styled-components';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { AdvancedImage } from '@cloudinary/react';
import { InputBlock } from './InputBlock';
import { useWindowResize } from '../../../../hooks/Window/useWindowResize';
import { useDevicePixelRatio } from '../../../../hooks/useDevicePixelRatio';
import { useCloudinary } from '../../../../hooks/Cloudinary/useCloudinary';
import { InputWithImageLinkType } from '../../../../../../types/inputWithImageLinkType';

type InputWithImageLinkBlockProps = {
  block: InputWithImageLinkType;
};

export const InputWithImageLinkCard = ({
  item: { title, link, image, label, validation, key },
}: {
  item: InputWithImageLinkType;
}) => {
  const imgId = image?.public_id || image?.publicId;
  const cld = useCloudinary();
  const devicePixelRatio = useDevicePixelRatio();
  const [cldImage, setCldImage] = useState(
    (imgId &&
      cld
        .image(imgId)
        .resize(
          fill()
            .width(Math.round(1120 / devicePixelRatio))
            .gravity(autoGravity()),
        )
        .delivery(dpr(devicePixelRatio))
        .quality('auto:best')
        .format('auto')) ||
      undefined,
  );

  const advancedImage = cldImage ? <AdvancedImage alt={image?.alt || image?.altText} cldImg={cldImage} /> : undefined;

  const setResponsiveImage = useCallback(() => {
    if (typeof window !== 'undefined') {
      if (image && imgId) {
        if (window.innerWidth <= parseInt(breakpoints.m, 10)) {
          const width = Math.round(window.innerWidth / devicePixelRatio);
          const height = Math.round(window.innerWidth / devicePixelRatio);
          setCldImage(
            cld
              .image(imgId)
              .resize(fill().width(width).height(height).gravity(autoGravity()))
              .delivery(dpr(devicePixelRatio))
              .quality('auto:best')
              .format('auto'),
          );
        } else {
          // If screen is large, use max 1120 (container) as width
          const width = Math.round(1120 / devicePixelRatio);
          setCldImage(
            cld
              .image(imgId)
              .resize(fill().width(width).gravity(autoGravity()))
              .delivery(dpr(devicePixelRatio))
              .quality('auto:best')
              .format('auto'),
          );
        }
      }
    }
  }, [cld, devicePixelRatio, image, imgId]);

  useEffect(() => {
    setResponsiveImage();
  }, [setResponsiveImage]);

  useWindowResize(() => {
    setResponsiveImage();
  });

  return (
    <BlockWrapper>
      <ImageWrapper>{advancedImage}</ImageWrapper>
      <TextWrapper>
        {title && (
          <HeaderText tag="h2" variant={TextVariant.Header2}>
            {title}
          </HeaderText>
        )}
        <InputBlock item={{ link, label, validation, key }} />
      </TextWrapper>
    </BlockWrapper>
  );
};

export const InputWithImageLinkBlock = ({ block }: InputWithImageLinkBlockProps) => (
  <Grid className="InputWithImageLinksBlock">
    <GridRow>
      <GridCol s="12" m="12" l="12" xl="12" key={block.key}>
        <InputWithImageLinkCard item={block} />
      </GridCol>
    </GridRow>
  </Grid>
);

const ImageWrapper = styled.figure`
  position: relative;
  margin: 0;
  border-radius: ${radius.m};
  max-height: 600px;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    border-radius: ${radius.m};

    @media (max-width: ${breakpoints.s}) {
      display: none;
    }
  }
  img {
    border-radius: ${radius.m};
    width: 100%;
    display: block;
    max-height: 600px;
    object-fit: cover;
    @media (max-width: ${breakpoints.s}) {
      border-radius: 0;
    }
  }
  @media (max-width: ${breakpoints.s}) {
    border-radius: 0;
  }
`;

const HeaderText = styled(Text)`
  color: ${nafColor.signature.white || 'FFF'};

  @media (max-width: ${breakpoints.s}) {
    margin-top: 0;
    padding-top: ${spacing.space8};
  }
`;

const BlockWrapper = styled.div`
  position: relative;
  @media (max-width: ${breakpoints.s}) {
    margin: 0 -${spacing.space24};
  }
`;

const TextWrapper = styled.div`
  position: absolute;
  top: calc(50% - (156px / 2));
  left: ${spacing.space80};
  @media (max-width: ${breakpoints.m}) {
    min-width: 410px;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  @media (max-width: ${breakpoints.s}) {
    position: static;
    transform: none;
    min-width: 0;
    padding: ${spacing.space24};
    background: ${({ theme }) =>
      theme.background ? theme.background.brandInverted : themeLight.background.brandInverted};
  }
`;
